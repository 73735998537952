<template>
  <div>
    <b-button size="sm" variant="outline-primary" @click="addForm"
      >Add New</b-button
    >
    <br />
    <br />
    <b-card no-body class="card-company-table" v-if="!isMobile">
      <b-row>
        <b-col md="12">
          <b-card no-body class="card-company-table">
            <b-table
              v-if="!isLoading"
              :items="data"
              show-empty
              responsive
              :fields="fields"
              class="mb-0"
              :total-rows="rows"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(status)="data">
                <b-badge
                  pill
                  variant="light-success"
                  v-if="data.item.finish_time"
                  >Finish</b-badge
                >
                <b-badge pill variant="light-danger" v-else
                  >On Progress</b-badge
                >
              </template>
              <template #cell(action)="data">
                <b-link @click="editForm(data.item)" title="Upgrade">
                  <span>Edit</span>
                </b-link>
              </template>
            </b-table>
            <br />
            <b-pagination
              v-if="!isLoading"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="mt-12"
              pills
              align="center"
            ></b-pagination>
          </b-card>
          <template v-if="isLoading">
            <loading></loading>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="isMobile">
      <div v-if="isLoading">
        <loading></loading>
      </div>
      <template v-if="!isLoading">
        <b-card
          no-body
          class="card-company-table"
          v-for="(data, index) of timelineMobile"
          :key="index"
        >
          <div class="card-body" @click="viewDetail(data.schedule_id)">
            <b-row>
              <b-col md="12">
                <h4 style="margin-bottom:10px; margin-left:-10px;">
                  <span class="badge badge-light-primary">
                    {{ data.title_alias_name }}
                  </span>
                </h4>
                <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Ticket Number") }}</strong>
                  </div>
                  <div class="col-12">{{ data.ticket_number }}</div>
                </b-row>
                <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Service Impact") }}</strong>
                  </div>
                  <div class="col-12">{{ data.service_impact }}</div>
                </b-row>
                <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Description") }}</strong>
                  </div>
                  <div class="col-12">{{ data.schedule_task }}</div>
                </b-row>
                <br />
                <span
                  style="float:left"
                  class="card-text text-muted font-small-2"
                  >{{ data.date_inserted }}</span
                >
                <span
                  style="float:right;"
                  class="card-text text-muted font-small-2"
                  ><b-badge pill variant="light-danger" class="pull-right"
                    >On Progress</b-badge
                  ></span
                >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </template>
    </div>

    <b-modal id="form-add" centered title="Add Form" hide-footer>
      <validation-observer ref="simpleRules">
        <b-form ref="add_form">
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('form.page.fab.field1')">
                <validation-provider
                  #default="{ errors }"
                  name="Field 1"
                  rules="required"
                >
                  <b-form-input
                    v-model="field1"
                    id="textarea-default"
                    :placeholder="$t('form.page.fab.field1')"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group :label="$t('form.page.fab.field2')">
                <validation-provider
                  #default="{ errors }"
                  name="Field 2"
                  rules="required"
                >
                  <b-form-input
                    v-model="field2"
                    id="textarea-default"
                    :placeholder="$t('form.page.fab.field2')"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<style>
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
</style>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import { isMobile } from "mobile-device-detect";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BCardBody,
  BTable,
  BImg,
  BCard,
  BLink,
  BBadge,
  BMedia,
  BMediaAside,
  BCardTitle,
  BPagination,
} from "bootstrap-vue";
import { required, email } from "@validations";
import Loading from "@core/components/loading/Loading.vue";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    //HistoryTiket,
    BCardBody,
    BTable,
    BImg,
    BCard,
    Loading,
    BLink,
    BBadge,
    BMedia,
    BMediaAside,
    BCardTitle,
    BPagination,
    BFormSelectOption,
  },
  data() {
    return {
      currentPage: 1,
      isMobile: isMobile,
      perPage: 5,
      name: "",
      data: [],
      selectedRow: null,
      field1: null,
      field2: null,
      isLoading: false,
      isEdit: false,
      fields: [
        { key: "field1", label: "field1" },
        { key: "field2", label: "field2" },
        { key: "action", label: "action" },
      ],
    };
  },
  computed: {
    rows() {
      return this.data.length;
    },
  },
  methods: {
    init(route) {
      this.getData();
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.isEdit == false) {
            if (confirm("Are you sure want to add this data ?")) {
              // eslint-disable-next-line
              axios
                .post("fab/add", { field1: this.field1, field2: this.field2 })
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Load Dashboard",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.data.message == "Expired token") {
                    localStorage.clear();
                    this.$router.push({ name: "login" });
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Load Dashboard",
                        icon: "EditIcon",
                        variant: "success",
                        text: "Session Expired, Please Login Again",
                      },
                    });
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Load Dashboard",
                        icon: "EditIcon",
                        variant: "success",
                        text: error.response.data.message,
                      },
                    });
                  }
                })
                .finally(() => {
                  this.isLoading = false;
                  this.getData();
                });
              this.$bvModal.hide("form-add");
              this.field1 = null;
              this.field2 = null;
            }
          } else {
            if (confirm("Are you sure want to update this data ?")) {
              // eslint-disable-next-line
              axios
                .put("fab/update", {
                  id: this.selectedRow,
                  field1: this.field1,
                  field2: this.field2,
                })
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Load Dashboard",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.data.message == "Expired token") {
                    localStorage.clear();
                    this.$router.push({ name: "login" });
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Load Dashboard",
                        icon: "EditIcon",
                        variant: "success",
                        text: "Session Expired, Please Login Again",
                      },
                    });
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Load Dashboard",
                        icon: "EditIcon",
                        variant: "success",
                        text: error.response.data.message,
                      },
                    });
                  }
                })
                .finally(() => {
                  this.isLoading = false;
                  this.getData();
                });
              this.$bvModal.hide("form-add");
              this.field1 = null;
              this.field2 = null;
            }
          }
        }
      });
    },
    getData: function() {
      this.isLoading = true;
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .get("fab/list")
        .then((response) => {
          this.data = response.data.data;
          // if(this.isMobile){
          //   this.timelineMobile = this.timeline.slice(0,5);
          // }

          this.isLoading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Load Dashboard",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Load Dashboard",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addForm: function() {
      this.isEdit = false;
      this.field1 = null;
      this.field2 = null;
      this.$bvModal.show("form-add");
    },
    editForm: function(a) {
      this.isEdit = true;
      this.field1 = a.field1;
      this.field2 = a.field2;
      this.selectedRow = a.id;
      this.$bvModal.show("form-add");
    },
  },
  mounted() {
    this.init();
  },
};
</script>
